<template>
  <!-- 问答讨论 / 问答讨论详情 -->
  <div class="main-contain">
    <!-- <div class="head">
      <div class="head-title">问答讨论 / 问答讨论详情</div>
      <div class="returnbt">
        <div class="bt" @click="toback">返回</div>
      </div>
    </div>
    <div class="line"></div>-->
    <div class="item">
      <div class="replybt" style="display: flex;justify-content: flex-end;margin-right:10px">
        <el-button type="primary" class="bt btactive" @click="replybt">回复</el-button>
      </div>
      <div style="display: flex;align-self:center;">
        <div class="title">{{ item.title }}</div>
        <div class="item-two">
          <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">置顶</div>
          <div v-if="item.is_teacher_join === 1" class="item-two-bt">教师参与</div>
          <div v-if="Number(item.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
        </div>
      </div>
      <div class="text-contain">
        <div class="text" v-html="item.content"></div>
      </div>
      <div class="bottom-contain">
        <div class="bottom-left">
          <el-image class="elimage" :src="item.avatar">
            <template slot="error">
              <el-image :src="avater1"></el-image>
            </template>
          </el-image>
          <div class="teach">{{ item.author_name }}</div>
          <div class="time">{{ item.created_at | commFormat }}</div>
          <div class="time">发布</div>
        </div>
        <div class="bottom-right">
          <div class="bottom-right-item" @click="setParentLike(item)">
            <template v-if="item.like_status === 0">
              <img class="elimage1 elimage-icons" :src="like_d" />
              <div class="num">{{ item.like_count }}</div>
            </template>
            <template v-else-if="item.like_status === 1">
              <img class="elimage1 elimage-icons" :src="like_c" />
              <div class="num">{{ item.like_count }}</div>
            </template>
          </div>
          <div class="line2"></div>
          <div class="bottom-right-item">
            <img class="elimage2 elimage-icons" :src="view_d" />
            <div class="num">{{ item.view_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="totaltitle">共{{ total }}条回复</div>
    <div v-if="dialogVisible">
      <div slot="dialogbody" class="dialogbody">
        <Tinymce
          ref="editor"
          v-model="queryParams.content"
          style="height: auto; border-radius: 22px"
          @setInput="getValue"
        ></Tinymce>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </div>
    <div class="replycontain" v-if="delReset">
      <div class="replyitem" v-for="(ele, index) in replylist" :key="index">
        <div class="itemup">
          <template v-if="!ele.to_user_name">
            <el-image class="elimage" :src="ele.avatar">
              <template slot="error">
                <el-image :src="avater1"></el-image>
              </template>
            </el-image>
            <div class="nametext">{{ ele.user_name }}</div>
          </template>
          <template v-else>
            <div class="nametext">
              {{ ele.user_name }}&nbsp;&nbsp;&nbsp;&nbsp;回复&nbsp;&nbsp;&nbsp;&nbsp;{{
              ele.to_user_name }}
            </div>
          </template>
          <div class="timetext">{{ ele.created_at | commFormat }}</div>
          <div class="item-two">
            <div v-if="Number(ele.has_publish) === 0" class="item-two-bt item-two-bt-active-1">待审核</div>
          </div>
        </div>
        <div class="itembottom">
          <div class="left">
            <div v-html="ele.content"></div>
          </div>
          <div class="right">
            <div class="bottom-right-item" @click="setlike(ele)">
              <template v-if="ele.like_status === 0">
                <img class="elimage2 elimage-icons" :src="like_d" />
                <div class="num">{{ ele.like_count }}</div>
              </template>
              <template v-else-if="ele.like_status === 1">
                <img class="elimage2 elimage-icons" :src="like_c" />
                <div class="num">{{ ele.like_count }}</div>
              </template>
            </div>
            <div class="line2"></div>
            <div class="bottom-right-item" @click="childreply(ele)">
              <img class="elimage3 elimage-icons" :src="review_d" />
              <div class="num">{{ ele.reply_count }}</div>
            </div>
            <div class="line2" v-if="ele.is_mine == 1"></div>
            <div class="bottom-right-item" @click="deletreply(ele)" v-if="ele.is_mine == 1">
              <img class="elimage3 elimage-icons" :src="del_d" />
            </div>
          </div>
        </div>
        <div v-if="ele.replylistflag" style="margin-bottom: 20px;">
          <div slot="dialogbody" class="dialogbody">
            <Tinymce
              ref="editor"
              v-model="queryParams.content"
              style="height: auto; border-radius: 22px"
              @setInput="getValue"
            ></Tinymce>
          </div>
          <div slot="dialogfooter" class="dialogfooter">
            <div class="bt" @click="canclefollowUp(ele)">取消</div>
            <div class="bt btactive" @click="followupsubmint(ele)">确定</div>
          </div>
        </div>
        <div class="replychild">
          <div class="replyitem" v-for="(item1, index) in ele.child" :key="index">
            <div class="itemup">
              <el-image class="elimage" :src="item1.avatar">
                <template slot="error">
                  <el-image :src="avater1"></el-image>
                </template>
              </el-image>
              <div class="nametext">
                <span>
                  {{ item1.user_name }}
                  <span v-if="item1.to_user_name">回复 {{ item1.to_user_name }}</span>
                </span>
                <!-- <span v-else>{{ item1.user_name }} 回复 {{ item.user_name }}</span> -->
              </div>
              <div class="timetext">{{ item1.created_at | commFormat }}</div>
              <div class="item-two">
                <div
                  v-if="Number(item1.has_publish) === 0"
                  class="item-two-bt item-two-bt-active-1"
                >待审核</div>
              </div>
            </div>
            <div class="itembottom">
              <div class="left">
                <div v-html="item1.content"></div>
              </div>
              <div class="right">
                <div class="bottom-right-item" @click="setlike(item1)">
                  <template v-if="item1.like_status === 0">
                    <img class="elimage2 elimage-icons" :src="like_d" />
                    <div class="num">{{ item1.like_count }}</div>
                  </template>
                  <template v-else-if="item1.like_status === 1">
                    <img class="elimage2 elimage-icons" :src="like_c" />
                    <div class="num">{{ item1.like_count }}</div>
                  </template>
                </div>
                <div class="line2"></div>
                <div class="bottom-right-item" @click="childreply1(item1)">
                  <img class="elimage3 elimage-icons" :src="review_d" />
                </div>
                <div class="line2" v-if="item1.is_mine == 1"></div>
                <div
                  class="bottom-right-item"
                  v-if="item1.is_mine == 1"
                  @click="deletchildreply(item1)"
                >
                  <img class="elimage3 elimage-icons" :src="del_d" />
                </div>
              </div>
            </div>
            <div v-if="item1.replylistflag1" style="margin-bottom: 20px;">
              <div slot="dialogbody" class="dialogbody">
                <Tinymce
                  ref="editor"
                  v-model="queryParams.content"
                  style="height: auto; border-radius: 22px"
                  @setInput="getValue"
                ></Tinymce>
              </div>
              <div slot="dialogfooter" class="dialogfooter">
                <div class="bt" @click="canclefollowUp(item1)">取消</div>
                <div class="bt btactive" @click="followupsubmint1(item1)">确定</div>
              </div>
            </div>
            <div :class="index % 2 === 0 ? 'line3' : ''"></div>
          </div>
        </div>
        <div :class="index % 2 === 0 ? 'line3' : ''"></div>
      </div>
    </div>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        :current-page="queryParams.page"
        @current-change="pageChange"
      ></el-pagination>
    </div>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
    
    </el-dialog>-->

    <!-- <Customdialog ref="customdialog" title="发表评论">

    </Customdialog>-->
  </div>
</template>
<script>
// import Customdialog from "@/components/customdialog.vue";
import { autoImgCorrection } from "@/utils/index"
import {
  getQaReplyList,
  addQaReplyAdd,
  setQaReplyLike,
  QaReplyDelete,
  qareply_deatil,
} from "@/api/qareply"
import { setQaLike } from "@/api/qa"
import dayjs from "dayjs"
import Tinymce from "@/components/VueTinymce/index.vue"

export default {
  name: "courselearning",
  components: { Tinymce },
  data () {
    return {
      dialogVisible: false,
      view_d: require("@/assets/查看_d.png"),
      like_c: require("@/assets/点赞_c.png"),
      like_d: require("@/assets/点赞_d.png"),
      review_d: require("@/assets/评论_d.png"),
      del_d: require("@/assets/删除_d.png"),
      avater1: require("@/assets/学生.png"),
      item: '',
      replylist: [],
      total: 0,
      queryParams: {
        qa_reply_id: '',
        qa_id: '',
        content: '',
        page: 1,
        per_page: 10
      },
      delReset: true,
    }
  },
  filters: {
    dataformat: function (value) {
      if (!value) return ""
      return dayjs.unix(value).format("mm")
    },
    commFormat: function (value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  watch: {
    replylist: {
      handler (newV) {
        this.$emit('childReset', true)
        this.delReset = false
        this.$nextTick(() => {
          this.delReset = true
        })
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    qa_id () {
      return this.$route.query.qa_id
    },
    id () {
      return this.$route.query.id
    },
  },
  created () {
  },
  methods: {
    initdata (obj) {
      // console.log('obj',obj);
      // this.item = obj
      this.queryParams.qa_id = obj.id
      this.getqareplylist({ qa_id: obj.id })
      this.initInfo({ id: obj.id })
    },
    // 主体详情
    initInfo(par) {
      qareply_deatil(par).then((res) => {
        if (res.code === 0) {
          this.item = res.data
        }
      })
    },


    async getqareplylist (params) {
      await getQaReplyList(params)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            response.data?.data.forEach(item => {
              item.content = autoImgCorrection(item.content)
              this.$set(item, "replylistflag", false)
              if (item.child.length > 0) {
                item.child.forEach(el => {
                  el.content = autoImgCorrection(el.content)
                  this.$set(el, "replylistflag1", false)
                })
              }
            })
          }
          this.replylist = response.data?.data
          this.total = response.data?.total
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    addQaReplyAdd () {

    },
    pageChange (val) {
      this.queryParams.page = val
      // this.queryParams.related_id = this.vr_id
      // this.getqalist(this.queryParams)
      this.getqareplylist(this.queryParams)
    },
    setParentLike (data) {
      this.item.like_status = this.item.like_status === 1 ? 0 : 1
      this.item.like_count = this.item.like_status ? this.item.like_count + 1 : this.item.like_count - 1
      this.setQaLike({ id: data.id })
    },
    setQaLike (params) {
      setQaLike(params)
        .then((response) => {
          if (response.code === 0) {
            this.getqareplylist({ qa_id: this.queryParams.qa_id })
          }
        })
        .catch((error) => { })
    },
    setlike (item) {
      this.setQaReplyLike({
        id: item.id,
      })
    },

    setQaReplyLike (params) {
      setQaReplyLike(params)
        .then((response) => {
          if (response.code === 0) {
            setTimeout(() => {
              this.getqareplylist({ qa_id: this.queryParams.qa_id })
            }, 1500)
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    deletreply (item) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.QaReplyDelete({
            id: item.id,
          })
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    deletchildreply (item) {
      this.$confirm("此操作将永久删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.QaReplyDelete({
            id: item.id,
          })
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },
    QaReplyDelete (params) {
      QaReplyDelete(params)
        .then((response) => {
          if (response.code === 0) {
            // this.$message.success("删除成功")
            this.getqareplylist({ qa_id: this.queryParams.qa_id })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    toback () {
      this.$router.push({
        path: "/home/virtualsimulationexperiment/experimentdetails",
        query: {
          id: this.id,
          btid: 7,
          returncomid: "Interactiveanswer",
        },
      })
    },
    replybt () {
      this.dialogVisible = !this.dialogVisible
      this.queryParams.qa_reply_id = ''
      this.queryParams.content = ''
    },
    canclebt () {
      this.dialogVisible = false
    },
    //取消追评
    canclefollowUp (row) {
      row.replylistflag = false
    },
    //追评
    followupsubmint (row) {
      // this.queryParams.qa_id = row.id
      this.submitbt()
      this.queryParams.content = ''
    },
    childreply (item) {
      this.queryParams.qa_reply_id = item.id
      this.queryParams.content = ''
      item.replylistflag = !item.replylistflag
    },
    //追评回复
    childreply1 (item) {
      item.replylistflag1 = !item.replylistflag1
      this.queryParams.content = ''
    },
    //追评回复
    followupsubmint1 (row) {
      this.queryParams.qa_reply_id = row.id
      this.submitbt()
      row.replylistflag1 = false
      this.queryParams.content = ''
    },
    submitbt () {
      addQaReplyAdd(this.queryParams)
        .then((response) => {
          if (response.code === 0) {
            this.$message.success("回复成功")
            this.dialogVisible = false
            this.getqareplylist({ qa_id: this.queryParams.qa_id })
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    // 获取富文本得值
    getValue (data) {
      this.queryParams.content = data
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  border: 1px solid transparent;
  margin-bottom: 20px;
  background: #f7f7f7;

  // overflow-y: scroll;
  // min-height: 500px;
  .head {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 10px;
    padding-top: 5px;
    margin-top: 30px;

    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
      margin-left: 20px;
    }
  }

  // .line {
  //   // border-bottom: 1px solid #cccccc;
  //   margin-left: 20px;
  //   margin-right: 20px;
  // }

  .returnbt {
    margin-top: 10px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }

  .item {
    // padding: 20px 0 20px 30px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background: #ffffff;

    .title {
      font-size: 20px;
      font-weight: 400;
      color: #222222;
      // width: 100%;
      // display: flex;
      // justify-content: flex-start;
      // align-items:center;
    }

    .item-two {
      // margin-top: 6px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-two-bt {
        width: 60px;
        min-width: 60px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        padding: 0 2px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        color: #409eff;
        background: #ffffff;
        border: 1px solid #409eff;
        border-radius: 4px;
        margin-top: 3px;
        margin-left: 10px;
      }

      .item-two-bt-active {
        color: #0fbf7f;
        width: 40px;
        min-width: 40px;
        border: 1px solid #0fbf7f;
      }
      .item-two-bt-active-1 {
        color: #f13218;
        width: 40px;
        min-width: 40px;
        border: 1px solid #f13218;
      }
    }

    .text-contain {
      // display: flex;
      // justify-content: space-between;
      // align-items: center;

      .text {
        margin-top: 10px;
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }
    }

    .bottom-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .bottom-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .teach {
          margin-left: 10px;
          margin-right: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .time {
          margin-right: 10px;
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
      }

      .bottom-right {
        padding-right: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bottom-right-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          // .elimage2 {
          //   width: 22px;
          //   height: 16px;
          // }

          // .elimage1 {
          //   width: 20px;
          //   height: 20px;
          //   margin-bottom: 4px;
          // }

          // .elimage3 {
          //   width: 19px;
          //   height: 16px;
          // }

          .num {
            margin-left: 6px;
            font-size: 15px;

            font-weight: 400;
            color: #a0a0a0;
          }
        }

        .line2 {
          margin-left: 20px;
          margin-right: 20px;
          height: 15px;
          border-right: 2px solid #e8e8e8;
        }
      }
    }

    .replybt {
      margin-right: 10px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      float: right;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
    }

    .bt {
      // margin-right: 20px;
      width: 84px;
      height: 38px;
      // line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }

  // .totaltitle {
  //   margin-left: 30px;
  //   margin-top: 10px;
  //   margin-bottom: 10px;
  //   font-size: 15px;

  //   font-weight: 400;
  //   color: #222222;
  // }
  .totaltitle {
    width: 100%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    //  border: 1px solid green;
    position: relative;
  }

  .totaltitle:before,
  .totaltitle:after {
    position: absolute;
    background: #ccc;
    content: '';
    height: 1px;
    top: 50%;
    width: 45%;
  }

  .totaltitle:before {
    left: 10px;
  }

  .totaltitle:after {
    right: 10px;
  }

  .replycontain {
    box-sizing: border-box;
    background: #ffffff;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;

    .replyitem {
      margin-bottom: 20px;
      margin-left: 40px;

      .itemup {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .elimage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .nametext {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 15px;

          font-weight: 400;
          color: #222222;
        }

        .timetext {
          font-size: 12px;

          font-weight: 400;
          color: #a0a0a0;
        }
        .item-two-bt {
          width: 60px;
          min-width: 60px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          padding: 0 2px;
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 400;
          color: #409eff;
          background: #ffffff;
          border: 1px solid #409eff;
          border-radius: 4px;
          margin-top: 3px;
          margin-left: 10px;
        }

        .item-two-bt-active {
          color: #0fbf7f;
          width: 40px;
          min-width: 40px;
          border: 1px solid #0fbf7f;
        }

        .item-two-bt-active-1 {
          color: #f13218;
          width: 40px;
          min-width: 40px;
          border: 1px solid #f13218;
        }
      }

      .itembottom {
        // margin-top: 6px;
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-end;

        .left {
          font-size: 15px;

          font-weight: 400;
          color: #222222;
          ::v-deep p {
            margin-top: 0px;
          }
        }

        .right {
          padding-right: 30px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .bottom-right-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            // .elimage1 {
            //   width: 22px;
            //   height: 16px;
            // }

            // .elimage2 {
            //   width: 19px;
            //   height: 21px;
            //   margin-bottom: 4px;
            // }

            // .elimage3 {
            //   width: 19px;
            //   height: 16px;
            // }

            .num {
              margin-left: 6px;
              font-size: 15px;

              font-weight: 400;
              color: #a0a0a0;
            }
          }

          .line2 {
            margin-left: 20px;
            margin-right: 20px;
            height: 15px;
            border-right: 2px solid #e8e8e8;
          }
        }
      }

      .line3 {
        margin-top: 15px;
        border-bottom: 1px solid #e8e8e8;
      }

      .replychild {
        margin-right: 20px;
        // background: #f7f7f7;
      }
    }
  }

  .dialogbody {
    padding: 20px;
    // z-index: 0;
  }

  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }

    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }

  .elimage-icons {
    width: 20px;
    height: 20px;
  }
}

.elpagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;

  .elpagination-title {
    margin-right: 4px;
  }
}
</style>
